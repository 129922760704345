<template>
  <div class="container">
    <div class="header">
      <img class="background" :src="require('../../assets/image/contact/head-bg.png')" alt="background">
      <div class="title-wrapper">
        <div class="title">
          <span>{{$t('nav.menu4')}}</span>
        </div>

        <div class="detail">
          <div>
            {{$t('contact.detail1')}}<br>
            {{$t('contact.detail2')}}
          </div>
        </div>
        <div class="mail-btn">
          <a href="mailto:info@hallonburg.com" title="info@hallonburg.com">info@hallonburg.com</a>
        </div>
      </div>
    </div>

    <div class="concat-form">
      <div class="title">
        {{$t('contact.fromTitle')}}
      </div>
      <div class="input">
        <input v-model="name" type="text" :placeholder="from.name">
      </div>
      <div class="input">
        <input v-model="company" type="text" :placeholder="from.company">
      </div>
      <div class="input">
        <input v-model="email" type="text" :placeholder="from.email">
      </div>
      <div class="input">
        <input v-model="subject" type="text" :placeholder="from.subject">
      </div>
      <div class="input">
        <input v-model="message" type="text" :placeholder="from.message">
      </div>
      <div class="agree">
        <input v-model="checkbox" type="checkbox" name="agree" value="1"/>
        {{$t('contact.agreeText')}}
        <router-link :to="{name: 'privacyPolicy'}">{{$t('contact.policy')}}</router-link>
        .
      </div>
      <a class="send" @click="send()">
        {{$t('contact.send')}}
      </a>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import NewsItem from '@/components/NewsItem.vue'

@Component(
  {
    components: {
      NewsItem
    }
  }
)
export default class Contact extends Vue {
  name = ''
  company = ''
  email = ''
  subject = ''
  message = ''
  checkbox = false

  send () {
    const reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
    if (!this.checkbox) {
      this.$layer.alert('Please review our privacy policy before submitting information！')
      return
    }
    if (!this.name) {
      this.$layer.msg('name is empty!')
      return
    }
    if (!this.email || !reg.test(this.email)) {
      this.$layer.msg('email is empty or illegal!')
      return
    }
    if (!this.subject) {
      this.$layer.msg('subject is empty!')
      return
    }
    if (!this.message) {
      this.$layer.msg('message is empty!')
      console.log('message为空')
      return
    }

    const object = {
      message: this.message,
      name: this.name,
      company: this.company,
      email: this.email,
      subject: this.subject
    }

    window.fetch('/optical/api/form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(object)
    }).then((response) => {
      if (response.status === 200) {
        this.$layer.msg('send success')
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  get from () {
    return {
      name: `${this.$t('contact.name')}*`,
      company: `${this.$t('contact.company')}*`,
      email: `${this.$t('contact.email')}*`,
      subject: `${this.$t('contact.subject')}*`,
      message: `${this.$t('contact.message')}*`
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import "../../assets/styles/global.styl"

  input {
    background none
    outline none
    border none
  }

  input:focus {
    border none
  }

  .background
    width 100% !important
    -webkit-user-drag none !important
    @media screen and (max-width: 1440px)
      width 1400px
      height 90vh
    @media screen and (max-width: 600px)
      width 1400px
      height 75vh

  .container
    display flex
    justify-content center
    align-items center
    flex-direction column

    .header
      position: relative
      display: flex
      justify-content center

      .title-wrapper
        position absolute
        top 35vh
        width 50vw
        margin 0 auto
        @media screen and (max-width: 800px)
          top 30vh
          width 80vw
        @media screen and (max-width: 800px)
          top 20vh

        .title
          font-family: "SourceSansPro"
          font-size: $huge
          text-align: left
          font-weight: bold
          color: rgb(255, 255, 255)
          @media screen and (max-width: 800px)
            font-size: 40px

          span
            padding 1vh 1vw
            background-color $blue

        .detail
          font-family: "SourceSansPro Regular"
          margin-top 5vw
          font-size $middle
          line-height 150%

          > *
            margin-top: 120px
            @media screen and (max-width: 600px)
              margin-top: 80px

        .mail-btn
          margin-top 50px

          a
            background-color $blue
            height 40px
            border 3px $blue solid
            border-radius 40px
            padding 10px 15px
            text-decoration none
            transition all .5s
            font-weight: 600
            font-family: "Open Sans", sans-serif
            line-height: 1
            vertical-align: middle
            color #fff

            &:hover
              color $blue
              background-color #fff

    .concat-form
      position relative
      width 50vw
      margin-bottom 8vh
      @media screen and (max-width: 800px)
        width 80vw

      .title
        font-family 'SourceSansPro'
        font-size $large
        font-weight bold
        margin 90px 0

        &:before
          content ''
          border-bottom 4px solid $blue
          position absolute
          width 80px
          height 60px

      > *
        margin 4vh 0
        font-size 14px
        font-family 'SourceSansPro Light'

      .input
        border-top 1px solid rgb(181, 181, 181)
        border-bottom 1px solid rgb(181, 181, 181)
        padding 2vh 1vw

        input
          font-size 16px
          width 45vw

      .agree
        line-height 150%
        font-family 'SourceSansPro Regular'
        font-size @small

        a
          cursor pointer
          color $blue
          text-decoration underline

      .send
        font-family 'SourceSansPro Regular'
        padding 10px 15px
        text-align center
        background #0070c0
        color #fff
        cursor pointer
        border-radius 2vw
</style>
