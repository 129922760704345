<template>
  <div class="container">
    <div class="header">
      <img class="background" :src="require('../../assets/image/news/head-bg.png')" alt="background">
      <div class="title-wrapper">
        <div class="title">
          <span>{{$t('nav.menu3')}}</span>
        </div>

        <div class="detail">
          <div>
            {{$t('news.title1')}}
          </div>
          <div>
            {{$t('news.title2')}}
          </div>
        </div>
      </div>
    </div>

    <NewsItem></NewsItem>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import NewsItem from '@/components/NewsItem.vue'

@Component(
  {
    components: {
      NewsItem
    }
  }
)
export default class News extends Vue {
}
</script>

<style lang="stylus" scoped>
  @import "../../assets/styles/global.styl"

  .background
    -webkit-user-drag none !important
    margin-bottom 40px
    width: 100%;
    @media screen and (max-width: 1080px)
      height 60vh
    @media screen and (max-width: 600px)
      height 40vh

  .container
    .header
      font-family 'SourceSansPro'
      position: relative
      display: flex
      justify-content center

      .title-wrapper
        position absolute
        top 35vh
        margin 0 auto
        @media screen and (max-width: 1080px)
          top 30vh
        @media screen and (max-width: 720px)
          top 25vh
          margin 0 15px
        @media screen and (max-width: 600px)
          top 15vh
          margin 0 15px

        .title
          font-size $huge
          text-align left
          color: rgb(255, 255, 255)
          @media screen and (max-width: 720px)
            font-size $large

          span
            padding 1vh 1vw
            font-family "SourceSansPro"
            background-color $blue

        .detail
          margin-top 5vw
          font-size $large
          font-weight bold
          @media screen and (max-width: 720px)
            font-size $middle

          > *
            margin-top 1vw
</style>
