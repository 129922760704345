var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-wrapper",style:({
    background: _vm.bgColor,
    color: _vm.fontColor
  })},[_c('div',{staticClass:"logo"},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.logoSrc,"alt":""},on:{"click":function($event){return _vm.routeTo('products')}}})]),_c('div',{staticClass:"menu"},[_c('ul',{staticClass:"menu-item left-menu-item"},_vm._l((_vm.menuList),function(item){return _c('li',{key:item.id,class:{ active: item.active },on:{"mouseover":function($event){item.id===0?_vm.onmouseover=true:_vm.onmouseover=false},"mouseout":function($event){_vm.onmouseover=false},"click":function($event){return _vm.clickMenuItem(item)}}},[_vm._v(_vm._s(_vm.$t(item.label))+" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(item.id===0),expression:"item.id===0"}],staticClass:"fa fa-angle-down fa-1x"}),_c('transition',{attrs:{"name":"fade"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.onmouseover),expression:"onmouseover"}],staticClass:"second-menu",style:({
                  background: _vm.bgColor,
                  color: _vm.fontColor,
                  padding: _vm.hasPadding?'0 10px 10px 10px':'0'
                }),on:{"mouseover":function($event){_vm.onmouseover=true},"mouseout":function($event){_vm.onmouseover=false}}},_vm._l((item.children),function(item){return _c('li',{key:item.id,staticClass:"second-menu-item",class:{ 'second-active': _vm.active(item) },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.clickSecondMenuItem(item)}}},[_vm._v(_vm._s(_vm.$t(item.label))+" ")])}),0)])],1)}),0),_c('ul',{staticClass:"menu-item right-menu-item"},_vm._l((_vm.languageList),function(item){return _c('li',{key:item.id,staticClass:"lang-item",class:{ active: item.active },style:({color: item.active?_vm.fontColor:'gray'}),on:{"click":function($event){return _vm.chooseLanguage(item)}}},[_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }