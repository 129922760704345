import { render, staticRenderFns } from "./OpticalCommunications.vue?vue&type=template&id=2bf0eaba&scoped=true&"
import script from "./OpticalCommunications.vue?vue&type=script&lang=ts&"
export * from "./OpticalCommunications.vue?vue&type=script&lang=ts&"
import style0 from "./OpticalCommunications.vue?vue&type=style&index=0&id=2bf0eaba&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bf0eaba",
  null
  
)

export default component.exports